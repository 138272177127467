import { FullVideoProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	animation,
	getThemeOptions,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.Module<FullVideoProps> = {
	schemaType: "module",
	component: "FullVideo",
	displayName: "Full Video",
	category: "content",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					key: "backgroundVideoURL",
					title: "Background video URL",
					validators: { format: "URL" },
					mandatory: true,
					helptext:
						"This video will be used as a background video. It will be muted and looped.",
				},
				{
					type: "ImageField",
					key: "backgroundImage",
					title: "Background video Image",
					mandatory: true,
					helptext: "This will be shown as a background.",
				},
				{
					title: "Open video in a modal",
					type: "ConditionalField",
					key: "openInModal",
					mandatory: true,
					options: [
						{
							name: "yes",
							value: true,
							title: "Yes",
						},

						{
							name: "no",
							value: false,
							title: "No",
						},
					],
					fields: [
						{
							condition: true,
							title: "Video to show in modal",
							type: "ConditionalField",
							key: "differentModalVideo",
							mandatory: true,
							options: [
								{
									name: "samevideo",
									value: false,
									title: "Same Video",
								},
								{
									name: "different",
									value: true,
									title: "Different Video",
								},
							],
							fields: [
								{
									condition: true,
									type: "TextField",
									key: "modalVideoURL",
									title: "Modal video URL",
									validators: { format: "URL" },
									mandatory: true,
								},
							],
						},
					],
				},

				{
					type: "UniqueCheck",
					key: "automaticSubtitles",
					title: "Subtitles",
					options: [{ title: "Add automatic subtitles" }],
				},
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
					mandatory: true,
					step: 10,
					min: 0,
					max: 100,
				},
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchor },
				{ ...themeSelector, options: getThemeOptions(["default", "inverse"]) },
			],
		},
	],

	default: {
		component: "FullVideo",
		backgroundVideoURL: "",
		backgroundImage: undefined,
		openInModal: true,
		differentModalVideo: false,
		modalVideoURL: "",
		automaticSubtitles: true,
		veil: 30,
		subtheme: "inverse",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FullVideo/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FullVideo/thumbnail@2x.png",
	},
};

export default schema;
