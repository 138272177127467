import { AwardsDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import InsightsSimpleDistributor from "@schemas/modules/InsightsSimpleDistributor";
import Quote from "@schemas/modules/Quote";
import { content } from "@schemas/presets";

const schema: Schema.Template<AwardsDetailProps> = {
	schemaType: "template",
	component: "AwardsDetail",
	displayName: "Awards",
	dataPacks: ["INSIGHTS"],

	type: {
		label: "Awards",
		value: "awards",
		mode: "detail",
	},

	content: [
		{
			type: "ImageField",
			key: "image",
			title: "Image",
			mandatory: true,
		},
		{
			type: "DateField",
			title: "Date",
			key: "insightDate",
			mandatory: true,
		},
		{
			type: "Wysiwyg",
			full: false,
			title: "Abstract",
			key: "abstract",
			isMockup: true,
			humanReadable: true,
			hideable: true,
			helptext: "Shown on the distributor and list card",
		},
		{
			type: "Wysiwyg",
			full: false,
			title: "Intro",
			key: "intro",
			mandatory: true,
			isMockup: true,
			humanReadable: true,
		},
		{ ...content, mandatory: true, hideable: false },
		{
			type: "ReferenceField",
			title: "Related insights",
			key: "data",
			sources: [{ structuredData: "INSIGHTS" }, { structuredData: "EVENT" }],
			maxItems: 4,
			hideable: true,
		},
		{
			type: "MultiCheckSelectGroup",
			title: "Select Categories",
			key: "categories",
			filled: true,
			mandatory: true,
			elements: [
				{
					placeholder: "Practice areas",
					key: "areas",
					source: "PRACTICE_AREA",
				},
				{
					placeholder: "Sectors",
					key: "sectors",
					source: "SECTOR",
				},
				{
					placeholder: "Office",
					key: "office",
					source: "OFFICES",
				},
				{
					placeholder: "Category",
					key: "awardCategories",
					source: "INSIGHT_CATEGORY",
				},
			],
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			whiteList: [
				"BasicContent",
				"BasicText",
				"FeaturedFacts",
				"IntroForm",
				"Quote",
				"TextCarousel",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
		},
		{
			type: "UniqueCheck",
			title: "Is award",
			key: "isAward",
			options: [{ title: "Is award" }],
			hidden: true,
		},
	],

	default: {
		type: "template",
		templateType: "AwardsDetail",
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "insightDate-DESC",
			sources: [{ structuredData: "INSIGHTS" }, { structuredData: "EVENT" }],
			quantity: 4,
		},
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [
				{ ...Quote.default },
				{ ...InsightsSimpleDistributor.default, subtheme: "default" },
			],
		},
		isAward: true,
	},

	thumbnails: {
		"1x": "/thumbnails/templates/AwardsDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/AwardsDetail/thumbnail@2x.png",
	},
};

export default schema;
