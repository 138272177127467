import { OfficesMapProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	animation,
	getThemeOptions,
	heading,
	link,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.Module<OfficesMapProps> = {
	schemaType: "module",
	component: "OfficesMap",
	category: "interactive",
	displayName: "Offices Map",
	dataPacks: ["OFFICES"],
	sectionList: {
		BasicTemplate: ["mainSection"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },
				{
					type: "ReferenceField",
					title: "Offices",
					key: "data",
					sources: [{ structuredData: "OFFICES" }],
					mandatory: true,
				},
				{ ...link, hideable: false, mandatory: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...animation },
			],
		},
	],

	default: {
		component: "OfficesMap",
		title: {
			content: "Lorem Ipsum",
			tag: "h3",
		},
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "recent-DESC",
			sources: [{ structuredData: "OFFICES" }],
			quantity: 0,
			fullRelations: true,
			fields: [
				"name",
				"location",
				"additionalInfo",
				"latitude",
				"longitude",
				"phone",
				"phone2",
				"email",
				"linkedin",
				"x",
				"link",
				"region",
				"country",
			],
		},
		link: { component: "Link" },
		anchorID: null,
		subtheme: "default",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/OfficesMap/thumbnail@1x.png",
		"2x": "/thumbnails/modules/OfficesMap/thumbnail@2x.png",
	},
};

export default schema;
