import { CareersListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

const schema: Schema.Template<CareersListProps> = {
	dataPacks: ["CAREERS"],
	schemaType: "template",
	displayName: "Careers List",
	component: "CareersList",
	type: {
		label: "Career",
		value: "career",
	},

	content: [
		{
			type: "ComponentArray",
			title: "Hero Section",
			maxItems: 1,
			whiteList: ["BasicHero"],
			key: "heroSection",
			contentType: "modules",
			mandatory: true,
		},
		{ ...heading, key: "listTitle" },
		{
			type: "CheckGroup",
			title: "Select template options",
			key: "options",
			options: [
				{ name: "search", title: "Search feature" },
				{ name: "services", title: "Filter [Services]" },
				{ name: "position", title: "Filter [Position]" },
				{ name: "office", title: "Filter [Office]" },
			],
		},
		{
			type: "Wysiwyg",
			full: false,
			title: "No Results text",
			key: "noResults",
			mandatory: true,
			humanReadable: true,
		},
		{
			type: "ReferenceField",
			title: "Careers",
			key: "data",
			sources: [{ structuredData: "CAREER" }],
			selectionType: ["auto"],
			mandatory: true,
		},
		{
			type: "NumberField",
			title: "Items per page",
			key: "itemsPerPage",
			min: 9,
			max: 40,
			mandatory: true,
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			whiteList: [
				"BasicContent",
				"BasicText",
				"FeaturedFacts",
				"IntroForm",
				"Quote",
				"TextCarousel",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
		},
	],

	config: [
		{
			type: "RadioGroup",
			title: "Pagination type",
			key: "paginationType",
			options: [
				{ name: "normal", value: "normal", title: "Normal pagination" },
				{ name: "infinite", value: "infinite", title: "Infinite scroll" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "template",
		templateType: "CareersList",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [],
		},
		listTitle: {
			content: "Lorem ipsum",
			tag: "h1",
		},
		noResults: "Sorry, no results were found. Please try a different search.",
		data: {
			mode: "auto",
			order: "careerDate-DESC",
			sources: [{ structuredData: "CAREER" }],
			fields: ["title", "abstract", "offices", "profile", "careerDate"],
		},
		itemsPerPage: 9,
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
		paginationType: "normal",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/CareersList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/CareersList/thumbnail@2x.png",
	},
};

export default schema;
