import { Error404Props } from "@autoTypes";
import { Schema } from "@griddo/core";
import { content, defaultLink, link } from "@schemas/presets";

const schema: Schema.Template<Error404Props> = {
	schemaType: "template",
	displayName: "Error 404",
	component: "Error404",
	type: { label: "Static", value: "static", special: "404" },
	singleInstance: true,

	content: [
		{ ...content, hideable: false, mandatory: true },
		{ ...link, hideable: false, mandatory: true },
		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			whiteList: [
				"BasicContent",
				"BasicText",
				"FeaturedFacts",
				"IntroForm",
				"Quote",
				"TextCarousel",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
		},
	],

	default: {
		type: "template",
		templateType: "Error404",
		content:
			"It seems that the page you are trying to access does not exist. Do you want to keep searching?",
		link: {
			...defaultLink,
			variant: "button3",
		},
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/Error404/thumbnail@1x.png",
		"2x": "/thumbnails/templates/Error404/thumbnail@2x.png",
	},
};

export default schema;
