import { SectorPracticeAreaListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import TextCarousel from "@schemas/modules/TextCarousel";

const schema: Schema.Template<SectorPracticeAreaListProps> = {
	dataPacks: ["SERVICES"],
	schemaType: "template",
	displayName: "Sector and Practice List",
	component: "SectorPracticeAreaList",
	type: { label: "Services", value: "Services" },

	content: [
		{
			type: "ComponentArray",
			title: "Hero Section",
			maxItems: 1,
			whiteList: ["BasicHero"],
			key: "heroSection",
			contentType: "modules",
			mandatory: true,
		},
		{
			type: "CheckGroup",
			title: "Select template options",
			key: "options",
			options: [
				{ name: "search", title: "Search feature" },
				{ name: "tabs", title: "Filter [Tabs]" },
			],
		},
		{
			type: "Wysiwyg",
			full: false,
			title: "No Results text",
			key: "noResults",
			mandatory: true,
			humanReadable: true,
		},
		{
			type: "ReferenceField",
			title: "Featured practice areas",
			key: "featuredAreasData",
			sources: [{ structuredData: "PRACTICE_AREA" }],
			selectionType: ["manual"],
			maxItems: 2,
			hideable: true,
		},
		{
			type: "ReferenceField",
			title: "Practice areas",
			key: "areasData",
			sources: [{ structuredData: "PRACTICE_AREA" }],
			selectionType: ["auto"],
			mandatory: true,
		},
		{
			type: "ReferenceField",
			title: "Featured sectors",
			key: "featuredSectorsData",
			sources: [{ structuredData: "SECTOR" }],
			selectionType: ["manual"],
			maxItems: 2,
			hideable: true,
		},
		{
			type: "ReferenceField",
			title: "Sectors",
			key: "sectorsData",
			sources: [{ structuredData: "SECTOR" }],
			selectionType: ["auto"],
			mandatory: true,
		},
		{
			title: "Which page should open?",
			type: "RadioGroup",
			key: "linkDirection",
			mandatory: false,
			options: [
				{
					value: "canonical",
					title: "Canonical site page",
					name: "pageOfCanonical",
				},
				{
					value: "site",
					title: "Current site page",
					name: "pageOfSite",
				},
			],
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			whiteList: [
				"BasicContent",
				"BasicText",
				"FeaturedFacts",
				"IntroForm",
				"Quote",
				"TextCarousel",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
		},
	],

	default: {
		type: "template",
		templateType: "SectorPracticeAreaList",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [],
		},
		noResults: "Sorry, no results were found. Please try a different search.",
		areasData: {
			mode: "auto",
			order: "recent-DESC",
			sources: [{ structuredData: "PRACTICE_AREA" }],
			fields: ["title"],
		},
		sectorsData: {
			mode: "auto",
			order: "recent-DESC",
			sources: [{ structuredData: "SECTOR" }],
			fields: ["title"],
		},
		linkDirection: "site",
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [{ ...TextCarousel.default, subtheme: "default-alt" }],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/SectorPracticeAreaList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/SectorPracticeAreaList/thumbnail@2x.png",
	},
};

export default schema;
