import { HeroCarouselSlideProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	themeSelector,
	loremIpsumParagraph,
	content,
	defaultLink,
	mainLink,
	secondaryLink,
} from "@schemas/presets";

const schema: Schema.Component<HeroCarouselSlideProps> = {
	schemaType: "component",
	component: "HeroCarouselSlide",
	category: "heros",
	displayName: "Hero Slide Carousel",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Background",
					key: "background",
					whiteList: ["Image", "BackgroundVideo"],
					hideable: true,
					helptext:
						"Recommended minimum image size: width 950px. We recommend that the video does not weigh more than 300kb",
				},
				{
					type: "TextField",
					title: "Phrase 1",
					key: "phrase1",
					helptext:
						"Write in plain text with capital letters and select the type of heading. Use underscores at the beginning and end of a word to highlight it. For example: Lorem _ipsum_",
					mandatory: true,
					isMockup: true,
					humanReadable: true,
				},
				{ ...heading, title: "Phrase 2", key: "phrase2" },
				{
					type: "ImageField",
					title: "Logo",
					key: "logo",
					hideable: true,
				},
				{ ...content },
				{ ...mainLink },
				{ ...secondaryLink },
			],
		},

		{
			title: "config",
			fields: [
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/components/HeroCarouselSlide/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/components/HeroCarouselSlide/Layouts/layout02.png",
						},
						{
							value: "layout03",
							img: "/thumbnails/components/HeroCarouselSlide/Layouts/layout03.png",
						},
					],
				},
				{ ...themeSelector },
				{
					type: "RadioGroup",
					key: "titleSize",
					title: "Title Size",
					mandatory: true,
					options: [
						{ name: "xl", value: "xl", title: "XL" },
						{ name: "l", value: "l", title: "L" },
						{ name: "m", value: "m", title: "M" },
					],
				},
			],
		},
	],

	default: {
		component: "HeroCarouselSlide",
		background: {
			image: { component: "Image" },
			backgroundVideo: { component: "BackgroundVideo" },
		},
		phrase1: "Lorem ipsum",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		content: loremIpsumParagraph,
		link1: {
			...defaultLink,
			variant: "button2",
			text: "Ver más",
		},
		link2: {
			...defaultLink,
			variant: "button3",
			text: "Ver más",
		},
		layout: "layout02",
		subtheme: "default",
		titleSize: "l",
	},
};

export default schema;
