import { InnerCardCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import BasicCard from "@schemas/components/BasicCard";
import {
	content,
	link,
	animation,
	loremIpsumParagraph,
} from "@schemas/presets";

const schema: Schema.Module<InnerCardCollectionProps> = {
	schemaType: "module",
	component: "InnerCardCollection",
	category: "collection",
	displayName: "Card Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...content, mandatory: true, hideable: false },
				{
					type: "ComponentArray",
					title: "Cards",
					key: "elements",
					whiteList: ["BasicCard"],
					contentType: "components",
					mandatory: true,
				},
				{ ...link },
			],
		},

		{
			title: "config",
			fields: [
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/CardCollection/Layouts/layout01.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/CardCollection/Layouts/layout02.png",
						},
						{
							value: "layout3",
							img: "/thumbnails/modules/CardCollection/Layouts/layout03.png",
						},
					],
				},
				{ ...animation },
			],
		},
	],

	default: {
		component: "InnerCardCollection",
		content: loremIpsumParagraph,
		link: { component: "Link" },
		layout: "layout2",
		elements: [
			{ ...BasicCard.default },
			{ ...BasicCard.default },
			{ ...BasicCard.default },
		],
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/CardCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CardCollection/thumbnail@2x.png",
	},
};

export default schema;
