import { TypographicVideoProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	animation,
	themeSelector,
	loremIpsumParagraph,
	getThemeOptions,
	link,
} from "@schemas/presets";

const schema: Schema.Module<TypographicVideoProps> = {
	schemaType: "module",
	component: "TypographicVideo",
	category: "featured",
	displayName: "Typographic Video",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					title: "Quote",
					key: "title",
					mandatory: true,
					isMockup: true,
					humanReadable: true,
				},
				{
					type: "TextField",
					title: "Subtitle",
					key: "subtitle",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},
				{
					title: "Background parallax",
					type: "ConditionalField",
					key: "backgroundParallax",
					mandatory: true,
					options: [
						{
							name: "image",
							value: "image",
							title: "Image",
						},
						{
							name: "video",
							value: "video",
							title: "Video",
						},
					],
					fields: [
						{
							condition: "image",
							title: "Background image",
							type: "ImageField",
							key: "image",
							mandatory: true,
						},
						{
							condition: "video",
							type: "TextField",
							key: "videoUrl",
							title: "Background video URL",
							validators: { format: "URL" },
							mandatory: true,
							helptext:
								"This video will be used as a background video. It will be muted and looped. Playlist URL are not allowed.",
						},
					],
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					...themeSelector,
					options: getThemeOptions(["default-alt", "inverse"]),
				},
				{ ...animation },
			],
		},
	],

	default: {
		component: "TypographicVideo",
		title: "Lorem ipsum",
		subtitle: loremIpsumParagraph,
		backgroundType: "video",
		link: { component: "Link", text: "Ver curriculum", variant: "button2" },
		anchorID: null,
		subtheme: "default-alt",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/TypographicVideo/thumbnail@1x.png",
		"2x": "/thumbnails/modules/TypographicVideo/thumbnail@2x.png",
	},
};

export default schema;
