export default {
	global: {
		skipToContent: "Vés al contingut",
		viewAll: "Mostra-ho tot",
		viewMore: "Veure més",
		and: "i",
	},
	a11y: {
		play: "Play video",
		playIcon: "icona de reproducció",
		closeModal: "Modal tancat",
		closeIcon: "Icona de tanca",
		homeLink: "Enllaç a la pàgina d'inici",
		openMenu: "Obre el menú",
		menuIcon: "Icona del menú",
		contentHide: "Amaga contingut",
		contentShow: "Mostra contingut",
		goTo: "Vés a",
	},
	Footer: {
		newsletterTitle: "Newsletter",
		connect: "Connecteu-vos amb ECIJA",
		facebook: "Facebook",
		flicker: "Flickr",
		instagram: "Instagram",
		linkedIn: "LinkedIn",
		newsletter: "Newsletter",
		podcast: "Podcast",
		snapchat: "Snapchat",
		tiktok: "TikTok",
		twitter: "X",
		youtube: "YouTube",
	},
	OfficesDistributor: {
		goTo: "Vés a la pàgina d'Office",
	},
	SectorsAndPracticesDistributor: {
		moreInfo: "Més informació",
		PRACTICE_AREA: "Àrees de pràctica",
		PRACTICE_AREA_mob: "Àrees",
		SECTOR: "Sectors",
		SECTOR_mob: "Sectors",
	},
	ShareButton: {
		share: "Comparteix",
	},
	PracticeAreaDetail: {
		areas: "Àrees de pràctica",
	},
	Quote: {
		Linkedin: "Vés a LinkedIn",
		Facebook: "Vés a  Facebook",
		Twitter: "Vés a X",
		Instagram: "Vés a Instagram",
		Youtube: "Vés a YouTube",
		Email: "Enviar e-maill",
		TikTok: "Vá para o TikTok",
	},
	PeopleDetail: {
		contact: "Contacte",
		services: "Serveis relacionats",
		insights: "Insights relacionats",
	},
	SectorDetail: {
		sectors: "Sectors",
	},
	PeopleDistributor: {
		goTo: "Mostra el perfil",
		services: "Veure professionals per àrea",
		search: "Cerca",
		areas: "Àrees de pràctica",
		sectors: "Sectors",
	},
	Lists: {
		loading: "Només uns segons, estem carregant els resultats",
		results: "resultats trobats",
		searchLabel: "Cercant ...",
		search: "Cerca",
		selected: "Filtres seleccionats",
		reset: "Suprimeix els filtres",
	},
	PeopleList: {
		areas: "Àrees de pràctica",
		sectors: "Sectors",
		services: "Serveis",
		position: "Posicions",
		offices: "Oficines",
		search: "Cerca professionals pel nom...",
	},
};
