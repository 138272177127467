import { QuoteProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import QuoteSlide from "@schemas/components/QuoteSlide";
import { anchor, animation } from "@schemas/presets";

const schema: Schema.Module<QuoteProps> = {
	schemaType: "module",
	component: "Quote",
	category: "content",
	displayName: "Quote",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					title: "Slides",
					key: "slides",
					whiteList: ["QuoteSlide"],
					contentType: "components",
					mandatory: true,
					maxItems: 5,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchor }, { ...animation }],
		},
	],

	default: {
		component: "Quote",
		slides: [{ ...QuoteSlide.default }],
		anchorID: null,
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Quote/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Quote/thumbnail@2x.png",
	},
};

export default schema;
