import { AWARDS } from "./AWARDS";
import { CAREER } from "./CAREER";
import { EVENT } from "./EVENT";
import { INSIGHTS } from "./INSIGHTS";
import { PEOPLE } from "./PEOPLE";
import { PRACTICE_AREA } from "./PRACTICE_AREA";
import { SECTOR } from "./SECTOR";

export default {
	AWARDS,
	CAREER,
	EVENT,
	INSIGHTS,
	PEOPLE,
	PRACTICE_AREA,
	SECTOR,
};
