import { TimelineProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	getThemeOptions,
	heading,
	link,
	themeSelector,
} from "@schemas/presets";

const schema: Schema.Module<TimelineProps> = {
	schemaType: "module",
	component: "Timeline",
	category: "interactive",
	displayName: "Timeline",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "ComponentArray",
					title: "Landmarks",
					key: "landmarks",
					mandatory: true,
					contentType: "components",
					whiteList: ["Landmark"],
				},

				{ ...link },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
			],
		},
	],

	default: {
		component: "Timeline",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		landmarks: [
			{
				component: "Landmark",
				year: 1997,
				content: "Where all began",
				imageField: undefined,
			},
			{
				component: "Landmark",
				year: new Date().getFullYear(),
				content: "Today",
				imageField: undefined,
			},
		],
		link: { component: "Link", text: "Ver más", variant: "button1" },
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Timeline/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Timeline/thumbnail@2x.png",
	},
};

export default schema;
