import Accordion from "./Accordion";
import BackgroundVideo from "./BackgroundVideo";
import BasicCard from "./BasicCard";
import CypherCard from "./CypherCard";
import Email from "./Email";
import Facebook from "./Facebook";
import FAQ from "./FAQ";
import FeaturedFact from "./FeaturedFact";
import HeroCarouselSlide from "./HeroCarouselSlide";
import HorizontalTab from "./HorizontalTab";
import Image from "./Image";
import Instagram from "./Instagram";
import Landmark from "./Landmark";
import Link from "./Link";
import Linkedin from "./Linkedin";
import LinkLite from "./LinkLite";
import LogoCollectionCard from "./LogoCollectionCard";
import Map from "./Map";
import Other from "./Other";
import QuoteSlide from "./QuoteSlide";
import TikTok from "./TikTok";
import Twitter from "./Twitter";
import VerticalTab from "./VerticalTab";
import Video from "./Video";
import Youtube from "./Youtube";

export default {
	Accordion,
	BackgroundVideo,
	BasicCard,
	CypherCard,
	Email,
	Facebook,
	FAQ,
	FeaturedFact,
	HeroCarouselSlide,
	HorizontalTab,
	Image,
	Instagram,
	Landmark,
	Link,
	Linkedin,
	LinkLite,
	LogoCollectionCard,
	Map,
	Other,
	QuoteSlide,
	TikTok,
	Twitter,
	VerticalTab,
	Video,
	Youtube,
};
