import { InsightsPinnedDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	animation,
	getThemeOptions,
	headingTextarea,
	headingType,
	link,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<InsightsPinnedDistributorProps> = {
	schemaType: "module",
	component: "InsightsPinnedDistributorProps",
	category: "distributors",
	displayName: "Insights Pinned Distributor",
	dataPacks: ["INSIGHTS", "EVENTS"],
	sectionList: {
		BasicTemplate: ["mainSection"],
		CareerDetail: ["relatedContent"],
		EventDetail: ["relatedContent"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...headingTextarea },
				{ ...headingType, mandatory: true },
				{
					type: "RadioGroup",
					title: "Show News abstract",
					key: "showNews",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					mandatory: true,
				},
				{
					type: "ReferenceField",
					title: "Insights",
					key: "data",
					sources: [
						{ structuredData: "INSIGHTS" },
						{ structuredData: "EVENT" },
						{ structuredData: "AWARDS" },
					],
					mandatory: true,
					maxItems: 9,
					helptext: "Max. 9 items",
				},
				{ ...link },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation, mandatory: false },
			],
		},
	],

	default: {
		component: "InsightsPinnedDistributor",
		title: "Lorem ipsum",
		tag: "h2",
		data: {
			mode: "auto",
			order: "insightDate-DESC",
			sources: [{ structuredData: "INSIGHTS" }, { structuredData: "EVENT" }],
			quantity: 4,
		},
		link: {
			component: "Link",
			text: "Ver todos los insights",
			variant: "button2",
		},
		showNews: true,
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/InsightsPinnedDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/InsightsPinnedDistributor/thumbnail@2x.png",
	},
};

export default schema;
