import { Schema } from "@griddo/core";

export const EVENT: Schema.PageContentType = {
	dataPacks: ["EVENTS"],
	title: "Events",
	local: false,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["EventDetail"],
		fields: [
			{
				type: "TextField",
				title: "Title",
				from: "title",
				key: "title",
				mandatory: true,
			},
			{
				type: "DateField",
				title: "Date",
				from: "insightDate",
				key: "insightDate",
				indexable: true,
			},
			{
				type: "TimeField",
				title: "Time",
				from: "eventTime",
				key: "eventTime",
			},
			{
				type: "TextField",
				title: "Place",
				from: "place",
				key: "place",
			},
			{
				type: "Wysiwyg",
				full: false,
				title: "Abstract",
				from: "abstract",
				key: "abstract",
			},
			{
				type: "ImageField",
				title: "Image",
				from: "image",
				key: "image",
			},
			{
				type: "MultiCheckSelect",
				title: "Category",
				placeholder: "Category",
				from: "categories.eventCategories",
				key: "eventCategories",
				source: "INSIGHT_CATEGORY",
			},
			{
				type: "MultiCheckSelect",
				title: "Languages",
				placeholder: "Languages",
				from: "categories.languages",
				key: "languages",
				source: "LANGUAGE",
			},
			{
				type: "MultiCheckSelect",
				title: "Office",
				placeholder: "Office",
				from: "categories.office",
				key: "office",
				source: "OFFICES",
			},
			{
				type: "MultiCheckSelect",
				title: "Practice areas",
				placeholder: "Practice areas",
				from: "categories.areas",
				key: "areas",
				source: "PRACTICE_AREA",
			},
			{
				type: "MultiCheckSelect",
				title: "Sectors",
				placeholder: "Sectors",
				from: "categories.sectors",
				key: "sectors",
				source: "SECTOR",
			},
		],
	},
};
