import { CareersDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	animation,
	content,
	getThemeOptions,
	headingTextarea,
	headingType,
	link,
	loremIpsumParagraph,
	separator,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<CareersDistributorProps> = {
	schemaType: "module",
	component: "CareersDistributor",
	category: "distributors",
	displayName: "Careers Distributor",
	dataPacks: ["CAREERS"],
	sectionList: {
		BasicTemplate: ["mainSection"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...headingTextarea, hideable: true, mandatory: false },
				{ ...headingType },
				{ ...content },
				{
					type: "ReferenceField",
					title: "Careers",
					key: "data",
					sources: [{ structuredData: "CAREER" }],
					maxItems: 6,
					mandatory: true,
				},
				{ ...link },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/CareersDistributor/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/CareersDistributor/Layouts/layout02.png",
						},
					],
				},
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...separator },
				{ ...animation },
			],
		},
	],

	default: {
		component: "CareersDistributor",
		title: "Lorem ipsum",
		tag: "h2",
		content: loremIpsumParagraph,
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "careerDate-DESC",
			sources: [{ structuredData: "CAREER" }],
			quantity: 3,
			fields: ["title", "abstract", "offices", "profile", "careerDate"],
		},
		link: { component: "Link" },
		anchorID: null,
		layout: "layout02",
		subtheme: "default-alt",
		verticalSpacing: "medium",
		separator: true,
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/CareersDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CareersDistributor/thumbnail@2x.png",
	},
};

export default schema;
