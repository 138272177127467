import { InsightsDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import VerticalTab from "@schemas/modules/VerticalTabs";
import { content, heading } from "@schemas/presets";

const schema: Schema.Template<InsightsDetailProps> = {
	schemaType: "template",
	component: "InsightsDetail",
	displayName: "Insights & media center Detail",
	dataPacks: ["INSIGHTS"],

	type: {
		label: "Insights",
		value: "insights",
		mode: "detail",
	},

	content: [
		{ ...heading, title: "Hero title", key: "heroTitle" },
		{
			type: "RadioGroup",
			title: "Structured data type",
			key: "dataType",
			options: [
				{ name: "Article", value: "Article", title: "Article" },
				{ name: "NewsArticle", value: "NewsArticle", title: "NewsArticle" },
				{
					name: "PodcastEpisode",
					value: "PodcastEpisode",
					title: "PodcastEpisode",
				},
				{
					name: "PublicationIssue",
					value: "PublicationIssue",
					title: "PublicationIssue",
				},
				{ name: "CreativeWork", value: "CreativeWork", title: "CreativeWork" },
			],
			helptext: "CreativeWork is a generic type. More info https://schema.org/",
			mandatory: true,
		},
		{
			type: "DateField",
			title: "Date",
			key: "insightDate",
			hideable: true,
		},
		{
			type: "Wysiwyg",
			full: false,
			title: "Abstract",
			key: "abstract",
			isMockup: true,
			humanReadable: true,
		},
		{
			type: "TextField",
			title: "Subtitle",
			key: "subtitle",
			hideable: true,
			humanReadable: true,
		},
		{ ...content, full: true, title: "Content 1", key: "content1" },
		{
			type: "ImageField",
			key: "image",
			title: "Image",
			hideable: true,
			mandatory: true,
		},
		{ ...content, full: true, title: "Content 2", key: "content2" },
		{
			key: "file",
			title: "Download archive",
			type: "FileField",
			hideable: true,
		},
		{
			type: "CheckGroup",
			title: "Social media share",
			key: "share",
			hideable: true,
			options: [
				{ name: "facebook", title: "Facebook" },
				{ name: "linkedin", title: "Linkedin" },
				{ name: "x", title: "X (Twitter)" },
				{ name: "mail", title: "Mail" },
			],
		},
		{
			type: "MultiCheckSelectGroup",
			title: "Select Categories",
			key: "categories",
			filled: true,
			mandatory: true,
			elements: [
				{
					placeholder: "Insight Category",
					key: "insightCategories",
					source: "INSIGHT_CATEGORY",
				},
				{
					placeholder: "Office",
					key: "office",
					source: "OFFICES",
				},
				{
					placeholder: "Related partners",
					key: "relatedPartners",
					source: "PEOPLE",
				},
				{
					placeholder: "Practice areas",
					key: "areas",
					source: "PRACTICE_AREA",
				},
				{
					placeholder: "Sectors",
					key: "sectors",
					source: "SECTOR",
				},
				{
					placeholder: "Topics",
					key: "topics",
					source: "TOPICS",
				},
			],
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			whiteList: [
				"AccordionCollection",
				"BasicContent",
				"BasicText",
				"CypherCollection",
				"FAQCollection",
				"FeaturedFacts",
				"ImageBanner",
				"IntroForm",
				"LogoCollection",
				"Navigation",
				"Quote",
				"TextCarousel",
				"VerticalTabs",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
		},
		{
			type: "TextField",
			key: "lastModified",
			title: "Last modified",
			computed: () => new Date().toJSON(),
			readonly: true,
		},
	],

	config: [
		{
			type: "UniqueCheck",
			key: "breadcrumbsDesktop",
			title: "Breadcrumbs Desktop/Tablet",
			hideable: true,
			options: [{ title: "Display in tablet and desktop" }],
		},
		{
			type: "UniqueCheck",
			key: "breadcrumbs",
			title: "Breadcrumbs Mobile",
			hideable: true,
			options: [{ title: "Display in mobile" }],
		},
	],

	default: {
		type: "template",
		templateType: "InsightsDetail",
		heroTitle: { content: "Lorem ipsum", tag: "h1" },
		dataType: "CreativeWork",
		share: ["linkedin"],
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [{ ...VerticalTab.default }],
		},
		breadcrumbsDesktop: false,
		breadcrumbs: false,
	},

	thumbnails: {
		"1x": "/thumbnails/templates/InsightsDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/InsightsDetail/thumbnail@2x.png",
	},
};

export default schema;
