import { Schema } from "@griddo/core";

import ca_ES from "./ca_ES";
import en_GB from "./en_GB";
import es_ES from "./es_ES";
import pt_PT from "./pt_PT";

export const translations: Schema.Translations = {
	ca_ES,
	en_GB,
	es_ES,
	pt_PT,
};
