import { EventDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import AccordionCollection from "@schemas/modules/AccordionCollection";
import InsightsPinnedDistributor from "@schemas/modules/InsightsPinnedDistributor";
import IntroForm from "@schemas/modules/IntroForm";
import PeopleDistributor from "@schemas/modules/PeopleDistributor";
import { heading } from "@schemas/presets";

const schema: Schema.Template<EventDetailProps> = {
	schemaType: "template",
	component: "EventDetail",
	displayName: "Event",
	dataPacks: ["EVENTS"],

	type: {
		label: "Event",
		value: "event",
		mode: "detail",
	},

	content: [
		{ ...heading, title: "Hero title", key: "heroTitle" },
		{
			type: "ImageField",
			key: "image",
			title: "Image",
			mandatory: true,
			helptext: "Shown on the distributor and list card",
		},
		{
			type: "DateField",
			title: "Date",
			key: "insightDate",
			mandatory: true,
		},
		{
			type: "TimeField",
			title: "Time",
			key: "eventTime",
			mandatory: true,
		},
		{
			type: "TextField",
			title: "Place",
			key: "place",
			mandatory: true,
		},
		{
			type: "Wysiwyg",
			full: false,
			title: "Abstract",
			key: "abstract",
			isMockup: true,
			humanReadable: true,
			hideable: true,
			helptext: "Shown on the distributor and list card",
		},
		{
			type: "UniqueCheck",
			title: "Category chips",
			key: "showCategoryChips",
			options: [{ title: "Show category chips" }],
		},
		{
			type: "CheckGroup",
			title: "Social media share",
			key: "share",
			hideable: true,
			options: [
				{ name: "facebook", title: "Facebook" },
				{ name: "linkedin", title: "Linkedin" },
				{ name: "x", title: "X (Twitter)" },
				{ name: "mail", title: "Mail" },
			],
		},
		{
			type: "MultiCheckSelectGroup",
			title: "Select Categories",
			key: "categories",
			filled: true,
			mandatory: true,
			elements: [
				{
					placeholder: "Category",
					key: "eventCategories",
					source: "INSIGHT_CATEGORY",
				},
				{
					placeholder: "Languages",
					key: "languages",
					source: "LANGUAGE",
				},
				{
					placeholder: "Office",
					key: "office",
					source: "OFFICES",
				},
				{
					placeholder: "Practice areas",
					key: "areas",
					source: "PRACTICE_AREA",
				},
				{
					placeholder: "Sectors",
					key: "sectors",
					source: "SECTOR",
				},
			],
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			whiteList: [
				"AccordionCollection",
				"BasicContent",
				"BasicText",
				"FeaturedFacts",
				"IntroForm",
				"Quote",
				"TextCarousel",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
		},
		{
			type: "TextField",
			key: "lastModified",
			title: "Last modified",
			computed: () => new Date().toJSON(),
			readonly: true,
		},
	],

	default: {
		type: "template",
		templateType: "EventDetail",
		heroTitle: { content: "Lorem ipsum", tag: "h2" },
		showCategoryChips: false,
		share: ["linkedin"],
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [
				{ ...IntroForm.default },
				{ ...AccordionCollection.default },
				{ ...PeopleDistributor.default },
				{ ...InsightsPinnedDistributor.default },
			],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/EventDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/EventDetail/thumbnail@2x.png",
	},
};

export default schema;
