import { Schema } from "@griddo/core";

const schema: Schema.Component = {
	schemaType: "component",
	component: "Landmark",
	displayName: "Landmark",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Year",
					type: "NumberField",
					key: "year",
					mandatory: true,
					hideable: false,
				},

				{
					title: "Content",
					type: "TextField",
					key: "content",
					humanReadable: true,
					isMockup: true,
				},

				{
					title: "Image",
					type: "ImageField",
					key: "imageField",
					hideable: false,
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "Landmark",
		year: new Date().getFullYear(),
		content: "Lorem ipsum",
		imageField: undefined,
	},
};

export default schema;
