import { ImageBannerProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	animation,
	content,
	heading,
	link,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<ImageBannerProps> = {
	schemaType: "module",
	component: "ImageBanner",
	displayName: "Image banner",
	category: "content",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ImageField",
					key: "background",
					title: "Background image",
					mandatory: true,
					helptext: "Recommended minimum image size: 792x514px",
				},
				{ ...heading },
				{ ...content },
				{ ...link, mandatory: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					type: "RadioGroup",
					title: "Full height",
					key: "fullHeight",
					mandatory: true,
					options: [
						{
							value: false,
							name: "no",
							title: "No",
						},
						{
							value: true,
							name: "yes",
							title: "Yes",
						},
					],
				},
				{
					type: "RadioGroup",
					title: "Content alignment",
					key: "alignment",
					mandatory: true,
					options: [
						{
							value: "left",
							name: "left",
							title: "Left",
						},
						{
							value: "center",
							name: "center",
							title: "Center",
						},
						{
							value: "right",
							name: "right",
							title: "Right",
						},
					],
				},
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/ImageBanner/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/ImageBanner/Layouts/layout02.png",
						},
					],
				},
				{ ...themeSelector },
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "ImageBanner",
		title: { content: "Lorem ipsum", tag: "h2" },
		content: null,
		link: { component: "Link", text: "Más información", variant: "button2" },
		anchor: null,
		fullHeight: false,
		alignment: "left",
		subtheme: "default",
		layout: "layout01",
		verticalSpacing: "medium",
		animation: "fadeIn",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ImageBanner/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ImageBanner/thumbnail@2x.png",
	},
};

export default schema;
