import { CareerDetailProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import BasicContent from "@schemas/modules/BasicContent";
import InsightsPinnedDistributor from "@schemas/modules/InsightsPinnedDistributor";
import IntroForm from "@schemas/modules/IntroForm";
import PeopleDistributor from "@schemas/modules/PeopleDistributor";
import { loremIpsumParagraph } from "@schemas/presets";

const schema: Schema.Template<CareerDetailProps> = {
	schemaType: "template",
	component: "CareerDetail",
	displayName: "Career",
	dataPacks: ["CAREERS"],

	type: {
		label: "Career",
		value: "career",
		mode: "detail",
	},

	content: [
		{
			type: "Wysiwyg",
			full: false,
			title: "Abstract",
			key: "abstract",
			humanReadable: true,
			mandatory: true,
		},
		{
			type: "DateField",
			title: "Date",
			key: "careerDate",
			mandatory: true,
		},
		{
			type: "ComponentContainer",
			title: "Intro",
			key: "intro",
			whiteList: ["IntroForm"],
			mandatory: true,
		},
		{
			type: "ArrayFieldGroup",
			title: "Job requirements",
			name: "Job requirement",
			key: "requirements",
			arrayType: "dropDown",
			fields: [
				{
					type: "TextField",
					title: "Bullet",
					key: "bullet",
					mandatory: true,
					humanReadable: true,
				},
			],
			mandatory: true,
		},
		{
			type: "ComponentContainer",
			title: "Who we are?",
			key: "who",
			whiteList: ["BasicContent"],
			hideable: true,
		},
		{
			type: "ArrayFieldGroup",
			title: "Additional job info",
			name: "Info",
			key: "info",
			arrayType: "dropDown",
			fields: [
				{
					type: "TextField",
					title: "Bullet",
					key: "bullet",
					mandatory: true,
					humanReadable: true,
				},
			],
			hideable: true,
		},
		{
			type: "ArrayFieldGroup",
			title: "Value contribution",
			name: "Value contribution",
			key: "valueContribution",
			arrayType: "dropDown",
			fields: [
				{
					type: "TextField",
					title: "Bullet",
					key: "bullet",
					mandatory: true,
					humanReadable: true,
				},
			],
			hideable: true,
		},
		{
			type: "MultiCheckSelectGroup",
			title: "Select Categories",
			key: "categories",
			filled: true,
			mandatory: true,
			elements: [
				{
					placeholder: "Practice areas",
					key: "areas",
					source: "PRACTICE_AREA",
				},
				{
					placeholder: "Sectors",
					key: "sectors",
					source: "SECTOR",
				},
				{
					placeholder: "Offices",
					key: "offices",
					source: "OFFICES",
				},
				{
					placeholder: "Profile (position)",
					key: "profile",
					source: "POSITION",
				},
			],
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			whiteList: [
				"BasicContent",
				"BasicText",
				"FeaturedFacts",
				"IntroForm",
				"Quote",
				"TextCarousel",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
		},
	],

	default: {
		type: "template",
		templateType: "CareerDetail",
		intro: { ...IntroForm.default, subtheme: "default-alt", separator: true },
		requirements: [
			{ bullet: loremIpsumParagraph },
			{ bullet: loremIpsumParagraph },
			{ bullet: loremIpsumParagraph },
		],
		who: { ...BasicContent.default, subtheme: "default-alt", separator: true },
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [
				{ ...PeopleDistributor.default },
				{ ...InsightsPinnedDistributor.default, subtheme: "default-alt" },
			],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/CareerDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/CareerDetail/thumbnail@2x.png",
	},
};

export default schema;
