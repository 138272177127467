import { PeopleListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import TextCarousel from "@schemas/modules/TextCarousel";
import { heading } from "@schemas/presets";

const schema: Schema.Template<PeopleListProps> = {
	dataPacks: ["PEOPLE"],
	schemaType: "template",
	displayName: "People List",
	component: "PeopleList",
	type: { label: "People", value: "People" },

	content: [
		{
			type: "ComponentArray",
			title: "Hero Section",
			maxItems: 1,
			whiteList: ["BasicHero"],
			key: "heroSection",
			contentType: "modules",
			mandatory: true,
		},
		{ ...heading, key: "listTitle" },
		{
			type: "CheckGroup",
			title: "Select template options",
			key: "options",
			options: [
				{ name: "search", title: "Search feature" },
				{ name: "services", title: "Filter [Services]" },
				{ name: "office", title: "Filter [Office]" },
				{ name: "position", title: "Filter [Position]" },
			],
		},
		{
			type: "Wysiwyg",
			full: false,
			title: "No Results text",
			key: "noResults",
			mandatory: true,
			humanReadable: true,
		},
		{
			type: "ReferenceField",
			title: "People",
			key: "data",
			sources: [{ structuredData: "PEOPLE" }],
			selectionType: ["auto"],
			mandatory: true,
		},
		{
			type: "NumberField",
			title: "Items per page",
			key: "itemsPerPage",
			min: 6,
			max: 40,
			mandatory: true,
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			whiteList: [
				"BasicContent",
				"BasicText",
				"FeaturedFacts",
				"ImageBanner",
				"IntroForm",
				"Quote",
				"TextCarousel",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
		},
	],

	config: [
		{
			type: "RadioGroup",
			title: "Pagination type",
			key: "paginationType",
			options: [
				{ name: "normal", value: "normal", title: "Normal pagination" },
				{ name: "infinite", value: "infinite", title: "Infinite scroll" },
			],
			mandatory: true,
		},
	],

	default: {
		type: "template",
		templateType: "PeopleList",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [],
		},
		listTitle: {
			content: "Lorem ipsum",
			tag: "h1",
		},
		noResults: "Sorry, no results were found. Please try a different search.",
		data: {
			mode: "auto",
			order: "customOrder-ASC",
			sources: [{ structuredData: "PEOPLE" }],
			fields: [
				"title",
				"image",
				"positionLabel",
				"linkedin",
				"email",
				"phone1",
				"phone2",
			],
		},
		itemsPerPage: 6,
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [{ ...TextCarousel.default, subtheme: "default-alt" }],
		},
		paginationType: "normal",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/PeopleList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/PeopleList/thumbnail@2x.png",
	},
};

export default schema;
