import { HorizontalTabProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import InnerBasicText from "@schemas/modules/InnerBasicText";
import { anchor, heading } from "@schemas/presets";

const schema: Schema.Component<HorizontalTabProps> = {
	schemaType: "component",
	component: "HorizontalTab",
	displayName: "Horizontal Tab",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, hideable: true, mandatory: false },
				{
					title: "Content",
					type: "ComponentArray",
					key: "content",
					whiteList: [
						"InnerBasicText",
						"InnerBasicContent",
						"InnerCardCollection",
						"InnerInsightsDistributor",
					],
					contentType: "components",
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [{ ...anchor }],
		},
	],

	default: {
		component: "HorizontalTab",
		title: { content: "Lorem ipsum", tag: "h4" },
		content: [{ ...InnerBasicText.default }],
		anchorID: null,
	},
};

export default schema;
