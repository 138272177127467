import { Schema } from "@griddo/core";

export const CAREER: Schema.PageContentType = {
	dataPacks: ["CAREERS"],
	title: "Careers",
	local: false,
	fromPage: true,
	translate: true,
	taxonomy: false,
	schema: {
		templates: ["CareerDetail"],
		fields: [
			{
				type: "TextField",
				title: "Title",
				from: "title",
				key: "title",
				mandatory: true,
			},
			{
				type: "Wysiwyg",
				full: false,
				title: "Abstract",
				from: "abstract",
				key: "abstract",
			},
			{
				type: "DateField",
				title: "Date",
				from: "careerDate",
				key: "careerDate",
				indexable: true,
			},
			{
				type: "MultiCheckSelect",
				title: "Practice areas",
				placeholder: "Practice areas",
				from: "categories.areas",
				key: "areas",
				source: "PRACTICE_AREA",
			},
			{
				type: "MultiCheckSelect",
				title: "Sectors",
				placeholder: "Sectors",
				from: "categories.sectors",
				key: "sectors",
				source: "SECTOR",
			},
			{
				type: "MultiCheckSelect",
				title: "Offices",
				placeholder: "Offices",
				from: "categories.offices",
				key: "offices",
				source: "OFFICES",
			},
			{
				type: "MultiCheckSelect",
				title: "Profile",
				placeholder: "Profile",
				from: "categories.profile",
				key: "profile",
				source: "POSITION",
			},
		],
	},
};
