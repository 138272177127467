import { COUNTRY } from "./COUNTRY";
import { INSIGHT_CATEGORY } from "./INSIGHT_CATEGORY";
import { LANGUAGE } from "./LANGUAGE";
import { POSITION } from "./POSITION";
import { REGION } from "./REGION";
import { TOPICS } from "./TOPICS";

export default {
	COUNTRY,
	INSIGHT_CATEGORY,
	LANGUAGE,
	POSITION,
	REGION,
	TOPICS,
};
