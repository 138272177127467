import AccordionCollection from "./AccordionCollection";
import BasicContent from "./BasicContent";
import BasicHero from "./BasicHero";
import BasicText from "./BasicText";
import CardCollection from "./CardCollection";
import CareersDistributor from "./CareersDistributor";
import CypherCollection from "./CypherCollection";
import FAQCollection from "./FAQCollection";
import FeaturedFacts from "./FeaturedFacts";
import Footer from "./Footer";
import FullVideo from "./FullVideo";
import Header from "./Header";
import HeroCarousel from "./HeroCarousel";
import HorizontalTabs from "./HorizontalTabs";
import ImageBanner from "./ImageBanner";
import InnerBasicContent from "./InnerBasicContent";
import InnerBasicText from "./InnerBasicText";
import InnerCardCollection from "./InnerCardCollection";
import InnerInsightsDistributor from "./InnerInsightsDistributor";
import InsightsHorizontalDistributor from "./InsightsHorizontalDistributor";
import InsightsPinnedDistributor from "./InsightsPinnedDistributor";
import InsightsSimpleDistributor from "./InsightsSimpleDistributor";
import IntroForm from "./IntroForm";
import LogoCollection from "./LogoCollection";
import Navigation from "./Navigation";
import OfficesDistributor from "./OfficesDistributor";
import OfficesMap from "./OfficesMap";
import PeopleDistributor from "./PeopleDistributor";
import Quote from "./Quote";
import SectorsAndPracticesDistributor from "./SectorsAndPracticesDistributor";
import TextCarousel from "./TextCarousel";
import Timeline from "./Timeline";
import TypographicVideo from "./TypographicVideo";
import VerticalTabs from "./VerticalTabs";
import Widget from "./Widget";
import Wysiwyg from "./Wysiwyg";

export default {
	AccordionCollection,
	BasicContent,
	BasicHero,
	BasicText,
	CardCollection,
	CareersDistributor,
	CypherCollection,
	FAQCollection,
	FeaturedFacts,
	Footer,
	FullVideo,
	Header,
	HeroCarousel,
	HorizontalTabs,
	ImageBanner,
	InnerBasicContent,
	InnerBasicText,
	InnerCardCollection,
	InnerInsightsDistributor,
	InsightsHorizontalDistributor,
	InsightsPinnedDistributor,
	InsightsSimpleDistributor,
	IntroForm,
	LogoCollection,
	Navigation,
	OfficesDistributor,
	OfficesMap,
	PeopleDistributor,
	Quote,
	SectorsAndPracticesDistributor,
	TextCarousel,
	Timeline,
	TypographicVideo,
	VerticalTabs,
	Wysiwyg,
	Widget,
};
