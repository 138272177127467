import { OfficesDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	animation,
	content,
	getThemeOptions,
	headingTextarea,
	headingType,
	link,
	loremIpsumParagraph,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<OfficesDistributorProps> = {
	schemaType: "module",
	component: "OfficesDistributor",
	category: "distributors",
	displayName: "Offices Distributor",
	dataPacks: ["OFFICES"],
	sectionList: {
		BasicTemplate: ["mainSection"],
		EventDetail: ["relatedContent"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...headingTextarea },
				{ ...headingType },
				{ ...content },
				{
					type: "ReferenceField",
					title: "Offices",
					key: "data",
					sources: [{ structuredData: "OFFICES" }],
					mandatory: true,
				},
				{
					type: "RadioGroup",
					title: "Cards decoration",
					key: "cardsDecoration",
					options: [
						{ name: "yes", value: true, title: "Yes" },
						{ name: "no", value: false, title: "No" },
					],
					helptext:
						"This behavior will only affect the decoration behind the cards",
					mandatory: true,
				},
				{ ...link },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/OfficesDistributor/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/OfficesDistributor/Layouts/layout02.png",
						},
					],
				},
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "OfficesDistributor",
		title: "Lorem ipsum",
		tag: "h2",
		content: loremIpsumParagraph,
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "recent-DESC",
			sources: [{ structuredData: "OFFICES" }],
			quantity: 4,
			fields: ["name", "image", "link"],
		},
		cardsDecoration: true,
		link: { component: "Link" },
		anchorID: null,
		layout: "layout01",
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/OfficesDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/OfficesDistributor/thumbnail@2x.png",
	},
};

export default schema;
