export default {
	global: {
		skipToContent: "Saltar para o conteúdo",
		viewAll: "Ver tudo",
		viewMore: "Ver mais",
		and: "e",
	},
	a11y: {
		play: "Play video",
		playIcon: "Ícone de reprodução",
		closeModal: "Fechar modal",
		closeIcon: "Ícone Fechar",
		homeLink: "Link para a página inicial",
		openMenu: "Abrir menu",
		menuIcon: "Menu icon",
		contentHide: "Ocultar conteúdo",
		contentShow: "Mostrar conteúdo",
		goTo: "Vá para",
	},
	Footer: {
		newsletterTitle: "Newsletter",
		connect: "Conecte-se com a ECIJA",
		facebook: "Facebook",
		flicker: "Flickr",
		instagram: "Instagram",
		linkedIn: "LinkedIn",
		newsletter: "Newsletter",
		podcast: "Podcast",
		snapchat: "Snapchat",
		tiktok: "TikTok",
		twitter: "X",
		youtube: "YouTube",
	},
	OfficesDistributor: {
		goTo: "Ir para a página do Office",
	},
	SectorsAndPracticesDistributor: {
		moreInfo: "Mais informações",
		PRACTICE_AREA: "Áreas de prática",
		PRACTICE_AREA_mob: "Áreas",
		SECTOR: "Setores",
		SECTOR_mob: "Setores",
	},
	ShareButton: {
		share: "Partilhar",
	},
	PracticeAreaDetail: {
		areas: "Áreas de prática",
	},
	Quote: {
		Linkedin: "Vá para o LinkedIn",
		Facebook: "Vá para o Facebook",
		Twitter: "Vá para o X",
		Instagram: "Vá para o Instagram",
		Youtube: "Vá para o YouTube",
		Email: "Enviar e-maill",
		TikTok: "Vá para o TikTok",
	},
	PeopleDetail: {
		contact: "Contacto",
		services: "Serviços relacionados",
		insights: "Insights relacionados",
	},
	SectorDetail: {
		sectors: "Setores",
	},
	PeopleDistributor: {
		goTo: "Ver perfil",
		services: "Ver profissionais por área",
		search: "Pesquisar",
		areas: "Áreas de prática",
		sectors: "Setores",
	},
	Lists: {
		loading: "Apenas alguns segundos, estamos a carregar os resultados",
		results: "resultados encontrados",
		searchLabel: "Pesquisar...",
		search: "Pesquisar",
		selected: "Filtros selecionados",
		reset: "Apagar filtros",
	},
	PeopleList: {
		areas: "Áreas de prática",
		sectors: "Setores",
		services: "Serviços",
		position: "Posições",
		offices: "Sedes",
		search: "Procura profissionais por nome...",
	},
};
