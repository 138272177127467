import { LogoCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import LogoCollectionCard from "@schemas/components/LogoCollectionCard";
import {
	anchor,
	verticalSpacing,
	themeSelector,
	animation,
	heading,
	content,
	link,
} from "@schemas/presets";

const schema: Schema.Module<LogoCollectionProps> = {
	schemaType: "module",
	component: "LogoCollection",
	category: "collection",
	displayName: "Logo collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false },
				{ ...content, isMockup: false },
				{
					type: "ComponentArray",
					title: "Logo cards",
					key: "logos",
					whiteList: ["LogoCollectionCard"],
					mandatory: true,
					contentType: "components",
				},
				{
					...link,
					title: "Main Link",
					key: "mainLink",
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchor },
				{ ...themeSelector },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						{
							value: "layout01",
							img: "/thumbnails/modules/LogoCollection/Layouts/layout01.png",
						},
						{
							value: "layout02",
							img: "/thumbnails/modules/LogoCollection/Layouts/layout02.png",
						},
					],
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "LogoCollection",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		logos: [
			{ ...LogoCollectionCard.default },
			{ ...LogoCollectionCard.default },
		],
		anchorID: null,
		subtheme: "default",
		layout: "layout01",
		mainLink: { component: "Link" },
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/LogoCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/LogoCollection/thumbnail@2x.png",
	},
};

export default schema;
