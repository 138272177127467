import { CAREERS } from "./CAREERS";
import { EVENTS } from "./EVENTS";
import { INSIGHTS } from "./INSIGHTS";
import { OFFICES } from "./OFFICES";
import { PEOPLE } from "./PEOPLE";
import { SERVICES } from "./SERVICES";

export default {
	CAREERS,
	EVENTS,
	INSIGHTS,
	OFFICES,
	PEOPLE,
	SERVICES,
};
