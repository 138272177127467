import { HorizontalTabsProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import HorizontalTab from "@schemas/components/HorizontalTab";
import {
	anchor,
	heading,
	verticalSpacing,
	themeSelector,
	loremIpsumParagraph,
	content,
	animation,
	getThemeOptions,
} from "@schemas/presets";

const schema: Schema.Module<HorizontalTabsProps> = {
	schemaType: "module",
	displayName: "Horizontal Tabs",
	category: "interactive",
	component: "HorizontalTabs",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, hideable: true, mandatory: false },
				{ ...content },
				{
					title: "Tabs",
					type: "ComponentArray",
					key: "tabs",
					whiteList: ["HorizontalTab"],
					contentType: "components",
					maxItems: 8,
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "HorizontalTabs",
		type: "HorizontalTabs",
		title: { content: "Lorem Ipsum", tag: "h3" },
		content: loremIpsumParagraph,
		tabs: [
			{ ...HorizontalTab.default },
			{ ...HorizontalTab.default },
			{ ...HorizontalTab.default },
		],
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HorizontalTabs/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HorizontalTabs/thumbnail@2x.png",
	},
};

export default schema;
