import { OfficesListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import BasicContent from "@schemas/modules/BasicContent";
import { heading } from "@schemas/presets";

const schema: Schema.Template<OfficesListProps> = {
	dataPacks: ["OFFICES"],
	schemaType: "template",
	displayName: "Offices List",
	component: "OfficesList",
	type: { label: "Offices", value: "Offices" },

	content: [
		{
			type: "ComponentArray",
			title: "Hero Section",
			maxItems: 1,
			whiteList: ["BasicHero"],
			key: "heroSection",
			contentType: "modules",
			mandatory: true,
		},
		{ ...heading, key: "listTitle" },
		{
			type: "CheckGroup",
			title: "Select template options",
			key: "options",
			options: [
				{ name: "search", title: "Search feature" },
				{ name: "areas", title: "Filter [Practice areas]" },
				{ name: "sectors", title: "Filter [Sectors]" },
				{ name: "regions", title: "Filter tab [Region]" },
			],
		},
		{
			type: "Wysiwyg",
			full: false,
			title: "No Results text",
			key: "noResults",
			mandatory: true,
			humanReadable: true,
		},
		{
			type: "ReferenceField",
			title: "Offices",
			key: "data",
			sources: [{ structuredData: "OFFICES" }],
			selectionType: ["auto"],
			mandatory: true,
		},
		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			whiteList: [
				"BasicContent",
				"BasicText",
				"FeaturedFacts",
				"IntroForm",
				"Quote",
				"TextCarousel",
				"Widget",
				"Wysiwyg",
			],
			contentType: "modules",
		},
	],

	default: {
		type: "template",
		templateType: "OfficesList",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [],
		},
		listTitle: {
			content: "Lorem ipsum",
			tag: "h1",
		},
		noResults: "Sorry, no results were found. Please try a different search.",
		data: {
			mode: "auto",
			order: "recent-DESC",
			sources: [{ structuredData: "OFFICES" }],
			fields: [
				"name",
				"location",
				"additionalInfo",
				"latitude",
				"longitude",
				"phone",
				"phone2",
				"email",
				"linkedin",
				"x",
				"link",
				"region",
				"country",
			],
		},
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [{ ...BasicContent.default }],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/OfficesList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/OfficesList/thumbnail@2x.png",
	},
};

export default schema;
