import { TextCarouselProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	animation,
	themeSelector,
	loremIpsumParagraph,
} from "@schemas/presets";

const schema: Schema.Module<TextCarouselProps> = {
	schemaType: "module",
	component: "TextCarousel",
	category: "featured",
	displayName: "Text Carousel",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					title: "Quote",
					key: "quote",
					mandatory: true,
					isMockup: true,
					humanReadable: true,
					helptext:
						"Use underscores at the beginning and end of a word to highlight it. For example:  Lorem _ipsum_",
				},
				{
					type: "UrlField",
					title: "Url",
					key: "url",
					advanced: true,
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchor }, { ...themeSelector }, { ...animation }],
		},
	],

	default: {
		component: "TextCarousel",
		quote: loremIpsumParagraph,
		anchorID: null,
		subtheme: "default",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/TextCarousel/thumbnail@1x.png",
		"2x": "/thumbnails/modules/TextCarousel/thumbnail@2x.png",
	},
};

export default schema;
