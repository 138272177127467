import { Schema } from "@griddo/core";

const schema: Schema.Languages = {
	es_ES: {
		name: "Spanish",
		label: "ES",
	},
	en_GB: {
		name: "English",
		label: "EN",
	},
	pt_PT: {
		name: "Portuguese",
		label: "PT",
	},
	ca_ES: {
		name: "Catalan",
		label: "CA",
	},
};

export default schema;
