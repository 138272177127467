//
// Griddo Modules
//
// Every Module and their types must be imported and reexported.
// Yes, a barrel index :)

import { Component } from "@griddo/core";
import * as React from "react";

// Types
import type { AccordionCollectionProps } from "./AccordionCollection";
import type { BasicContentProps } from "./BasicContent";
import type { BasicHeroProps } from "./BasicHero";
import type { BasicTextProps } from "./BasicText";
import type { CardCollectionProps } from "./CardCollection";
import type { CareersDistributorProps } from "./CareersDistributor";
import type { CypherCollectionProps } from "./CypherCollection";
import type { FAQCollectionProps } from "./FAQCollection";
import type { FeaturedFactsProps } from "./FeaturedFacts";
import type { FooterProps } from "./Footer";
import type { FullVideoProps } from "./FullVideo";
import type { HeaderProps } from "./Header";
import type { HeroCarouselProps } from "./HeroCarousel";
import type { HorizontalTabsProps } from "./HorizontalTabs";
import type { ImageBannerProps } from "./ImageBanner";
import type { InnerBasicContentProps } from "./InnerBasicContent";
import type { InnerBasicTextProps } from "./InnerBasicText";
import type { InnerCardCollectionProps } from "./InnerCardCollection";
import type { InnerInsightsDistributorProps } from "./InnerInsightsDistributor";
import type { InsightsHorizontalDistributorProps } from "./InsightsHorizontalDistributor";
import type { InsightsPinnedDistributorProps } from "./InsightsPinnedDistributor";
import type { InsightsSimpleDistributorProps } from "./InsightsSimpleDistributor";
import type { IntroFormProps } from "./IntroForm";
import type { LogoCollectionProps } from "./LogoCollection";
import type { NavigationProps } from "./Navigation";
import type { OfficesDistributorProps } from "./OfficesDistributor";
import type { OfficesMapProps } from "./OfficesMap";
import type { PeopleDistributorProps } from "./PeopleDistributor";
import type { QuoteProps } from "./Quote";
import type { SectorsAndPracticesDistributorProps } from "./SectorsAndPracticesDistributor";
import type { TextCarouselProps } from "./TextCarousel";
import type { TimelineProps } from "./Timeline";
import type { TypographicVideoProps } from "./TypographicVideo";
import type { VerticalTabsProps } from "./VerticalTabs";
import type { WidgetProps } from "./Widget";
import type { WysiwygProps } from "./Wysiwyg";

// Modules
const AccordionCollection = React.lazy(() => import("./AccordionCollection"));
const BasicContent = React.lazy(() => import("./BasicContent"));
const BasicHero = React.lazy(() => import("./BasicHero"));
const BasicText = React.lazy(() => import("./BasicText"));
const CardCollection = React.lazy(() => import("./CardCollection"));
const CareersDistributor = React.lazy(() => import("./CareersDistributor"));
const CypherCollection = React.lazy(() => import("./CypherCollection"));
const FAQCollection = React.lazy(() => import("./FAQCollection"));
const FeaturedFacts = React.lazy(() => import("./FeaturedFacts"));
const Footer = React.lazy(() => import("./Footer"));
const FullVideo = React.lazy(() => import("./FullVideo"));
const Header = React.lazy(() => import("./Header"));
const HeroCarousel = React.lazy(() => import("./HeroCarousel"));
const HorizontalTabs = React.lazy(() => import("./HorizontalTabs"));
const ImageBanner = React.lazy(() => import("./ImageBanner"));
const InnerBasicContent = React.lazy(() => import("./InnerBasicContent"));
const InnerBasicText = React.lazy(() => import("./InnerBasicText"));
const InnerCardCollection = React.lazy(() => import("./InnerCardCollection"));
const InnerInsightsDistributor = React.lazy(
	() => import("./InnerInsightsDistributor"),
);
const InsightsHorizontalDistributor = React.lazy(
	() => import("./InsightsHorizontalDistributor"),
);
const InsightsPinnedDistributor = React.lazy(
	() => import("./InsightsPinnedDistributor"),
);
const InsightsSimpleDistributor = React.lazy(
	() => import("./InsightsSimpleDistributor"),
);
const IntroForm = React.lazy(() => import("./IntroForm"));
const LogoCollection = React.lazy(() => import("./LogoCollection"));
const Navigation = React.lazy(() => import("./Navigation"));
const OfficesDistributor = React.lazy(() => import("./OfficesDistributor"));
const OfficesMap = React.lazy(() => import("./OfficesMap"));
const PeopleDistributor = React.lazy(() => import("./PeopleDistributor"));
const Quote = React.lazy(() => import("./Quote"));
const SectorsAndPracticesDistributor = React.lazy(
	() => import("./SectorsAndPracticesDistributor"),
);
const TextCarousel = React.lazy(() => import("./TextCarousel"));
const Timeline = React.lazy(() => import("./Timeline"));
const TypographicVideo = React.lazy(() => import("./TypographicVideo"));
const VerticalTabs = React.lazy(() => import("./VerticalTabs"));
const Wysiwyg = React.lazy(() => import("./Wysiwyg"));
const Widget = React.lazy(() => import("./Widget"));

// Modules for <GriddoModule>
const modules = {
	AccordionCollection,
	BasicContent,
	BasicHero,
	BasicText,
	CardCollection,
	CareersDistributor,
	CypherCollection,
	FAQCollection,
	FeaturedFacts,
	Footer,
	FullVideo,
	Header,
	HeroCarousel,
	HorizontalTabs,
	ImageBanner,
	InnerBasicContent,
	InnerBasicText,
	InnerCardCollection,
	InnerInsightsDistributor,
	InsightsHorizontalDistributor,
	InsightsPinnedDistributor,
	InsightsSimpleDistributor,
	IntroForm,
	LogoCollection,
	Navigation,
	OfficesDistributor,
	OfficesMap,
	PeopleDistributor,
	Quote,
	SectorsAndPracticesDistributor,
	TextCarousel,
	Timeline,
	TypographicVideo,
	VerticalTabs,
	Wysiwyg,
	Widget,
};

// Types for <GriddoModule>
type ModulesProps =
	| AccordionCollectionProps
	| BasicContentProps
	| BasicHeroProps
	| BasicTextProps
	| CardCollectionProps
	| CareersDistributorProps
	| CypherCollectionProps
	| FAQCollectionProps
	| FeaturedFactsProps
	| FooterProps
	| FullVideoProps
	| HeaderProps
	| HeroCarouselProps
	| HorizontalTabsProps
	| ImageBannerProps
	| InnerBasicContentProps
	| InnerBasicTextProps
	| InnerCardCollectionProps
	| InnerInsightsDistributorProps
	| InsightsHorizontalDistributorProps
	| InsightsPinnedDistributorProps
	| InsightsSimpleDistributorProps
	| IntroFormProps
	| LogoCollectionProps
	| NavigationProps
	| OfficesDistributorProps
	| OfficesMapProps
	| PeopleDistributorProps
	| QuoteProps
	| SectorsAndPracticesDistributorProps
	| TextCarouselProps
	| TimelineProps
	| TypographicVideoProps
	| VerticalTabsProps
	| WysiwygProps
	| WidgetProps;

// Module named export
export {
	AccordionCollection,
	BasicContent,
	BasicHero,
	BasicText,
	CardCollection,
	CareersDistributor,
	CypherCollection,
	FAQCollection,
	FeaturedFacts,
	Footer,
	FullVideo,
	Header,
	HeroCarousel,
	HorizontalTabs,
	InnerBasicContent,
	InnerBasicText,
	InnerCardCollection,
	InnerInsightsDistributor,
	InsightsHorizontalDistributor,
	InsightsPinnedDistributor,
	InsightsSimpleDistributor,
	IntroForm,
	Navigation,
	OfficesDistributor,
	OfficesMap,
	PeopleDistributor,
	Quote,
	SectorsAndPracticesDistributor,
	TextCarousel,
	Timeline,
	VerticalTabs,
	Wysiwyg,
	Widget,
};

/*
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱                       ╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱  You shall not pass!  ╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱                       ╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
*/

// Griddo bundle export
export default modules;

/**
 * `<GriddoModule>`
 *
 * Render modules with their Types dinamically and wrapped them with Griddo
 * functionalities.
 *
 * @example
 * <GriddoModule component="BasicModule" {...basicModuleProps} />
 *
 * Check out the [documentation](https://griddoio.notion.site/GriddoComponent-y-GriddoModule-en-la-instancia-bad16303bb4b49e0b696b62d1f6c40e9)
 */
export const GriddoModule = (props: ModulesProps) => (
	<Component libComponents={modules} {...props} />
);
