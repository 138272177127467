import { InsightsHorizontalDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	animation,
	getThemeOptions,
	headingTextarea,
	headingType,
	link,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<InsightsHorizontalDistributorProps> = {
	schemaType: "module",
	component: "InsightsHorizontalDistributor",
	category: "distributors",
	displayName: "Insights Horizontal Distributor",
	dataPacks: ["INSIGHTS", "EVENTS"],
	sectionList: {
		BasicTemplate: ["mainSection"],
		CareerDetail: ["relatedContent"],
		EventDetail: ["relatedContent"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...headingTextarea },
				{ ...headingType, mandatory: true },
				{
					type: "ReferenceField",
					title: "Insights",
					key: "data",
					sources: [
						{ structuredData: "INSIGHTS" },
						{ structuredData: "EVENT" },
						{ structuredData: "AWARDS" },
					],
					mandatory: true,
				},
				{ ...link },
				{
					type: "UniqueCheck",
					key: "filter",
					title: "Filter",
					options: [{ title: "Filter" }],
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					...themeSelector,
					options: getThemeOptions(["default", "default-alt"]),
				},
				{ ...verticalSpacing },
				{ ...animation, mandatory: false },
			],
		},
	],

	default: {
		component: "InsightsHorizontalDistributor",
		title: "Lorem ipsum",
		tag: "h2",
		data: {
			mode: "auto",
			order: "insightDate-DESC",
			sources: [{ structuredData: "INSIGHTS" }, { structuredData: "EVENT" }],
			quantity: 4,
		},
		link: { component: "Link", text: "Ver más", variant: "button2" },
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/InsightsHorizontalDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/InsightsHorizontalDistributor/thumbnail@2x.png",
	},
};

export default schema;
