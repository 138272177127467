import AwardsDetail from "./AwardsDetail";
import BasicTemplate from "./BasicTemplate";
import CareerDetail from "./CareerDetail";
import CareersList from "./CareersList";
import Error404 from "./Error404";
import EventDetail from "./EventDetail";
import InsightsDetail from "./InsightsDetail";
import InsightsList from "./InsightsList";
import OfficesList from "./OfficesList";
import PeopleDetail from "./PeopleDetail";
import PeopleList from "./PeopleList";
import PracticeAreaDetail from "./PracticeAreaDetail";
import SectorDetail from "./SectorDetail";
import SectorPracticeAreaList from "./SectorPracticeAreaList";

export default {
	AwardsDetail,
	BasicTemplate,
	CareerDetail,
	CareersList,
	Error404,
	EventDetail,
	InsightsDetail,
	InsightsList,
	OfficesList,
	PeopleDetail,
	PeopleList,
	PracticeAreaDetail,
	SectorDetail,
	SectorPracticeAreaList,
};
